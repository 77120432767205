import React, { createContext, useEffect, useState, useContext } from 'react';
import axios from "axios";
import { message } from 'antd';

export const END_POINT = '/api/auth';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const loadUserCredential = async () => {
    setLoading(true);
    const rs = await axios.get(`${END_POINT}/me`);
    setUser(rs.data);
    setLoading(false)
  }
  const login = async payload => {
    setLoading(true);
    try {
      const csrf = await axios.get(`${END_POINT}/csrf`);
      const rs = await axios.post(`${END_POINT}/login/password`, { ...payload, _csrf: csrf.data });
      setUser(rs.data);
      message.success(`Đăng nhập thành công`)
    } catch (err) {
      message.error(`Đăng nhập thất bại (${err.response.status})`)
    } finally {
      setLoading(false);
    }
  }
  const logout = async () => {
    setLoading(true);
    try {
      const csrf = await axios.get(`${END_POINT}/csrf`);
      await axios.post(`${END_POINT}/logout`, { _csrf: csrf.data});
      setUser(null);
      message.success(`Đăng xuất thành công`)
    } catch (err) {
      message.error(`Đăng xuất thất bại (${err.response.status})`)
    } finally {
      setLoading(false);
    }    
  }
  useEffect(() => {
    loadUserCredential();
  }, []);
  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useCredential = () => {
  const { user, loading, login, logout } = useContext(AuthContext);
  return { user, loading, login, logout };
}

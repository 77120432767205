import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import translate from './utils/translation';
import 'antd/dist/antd.css';

const Component = (
  <BrowserRouter>
    <IntlProvider locale="en" messages={translate('en')}>
        <App />
    </IntlProvider>
  </BrowserRouter>
);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(Component);
serviceWorker.register();

import enTranslation from '../locales/en.json';
import vnTranslation from '../locales/vi.json';

const getMessagesByLocale = (locale) => {
  switch (locale) {
    case 'en':
      return enTranslation;
    case 'vi':
      return vnTranslation;
    default:
      return enTranslation;
  }
};

export default getMessagesByLocale;

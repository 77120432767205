import React from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import { ViewportProvider } from './modules/utils/viewport';

import './styles/index.less';
import { AuthProvider } from './modules/auth/useCredential';
import CallBackPage from './modules/auth/CallBackPage';

const ChessPage = React.lazy(() => import("./modules/chess-page"));
const ChessRoom = React.lazy(() => import("./modules/chess-room"));

const Wrapper = styled.div`
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
`;

function App() {
  return (
    <Wrapper>
      <ViewportProvider>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<ChessPage />} />
            <Route path="/room/:rid" element={<ChessRoom />} />
            <Route path="/callback" element={<CallBackPage />} />
          </Routes>
        </AuthProvider>
      </ViewportProvider>
    </Wrapper>
  );
}

export default App;

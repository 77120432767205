import { useEffect } from 'react';
import queryString from 'query-string'
import { useLocation } from 'react-router';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

const CallBackPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const parsedSearch = queryString.parse(location.search);
    const parsedHash = queryString.parse(location.hash);
    if (parsedSearch['loginSuccess'] === null || parsedHash['_'] === '_') {
      message.success('Đăng nhập thành công')
    }
    if (parsedSearch['loginFailure'] === null) {
      message.error('Đăng nhập thất bại')
    }
    navigate('/', { replace: true });
  })
  return null;
}

export default CallBackPage;
